import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "10px",
    width: "100%"
  },
  tableContainer: {
    marginTop: 20
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#000",
    color: "white"
  },
  tableCell: {
    padding: "10px"
  },
  button: {
    marginTop: "20px"
  }
});

const OutstationPricing = ({ carData, onSave }) => {
  const classes = useStyles();
  const [outstationConstants, setOutstationConstants] = useState(
    carData.outstationPricing || {
      A: "",
      B: "",
      C: "",
      driver_allowance: "",
      night_charges: "",
      round_trip_discount_percentage: "",
      additional_cost1: "",
      additional_cost2: ""
    }
  );

  const handleOutstationChange = (field, value) => {
    setOutstationConstants((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    onSave(outstationConstants);
  };

  return (
    <div>
      <h3>Outstation Pricing</h3>
      {Object.keys(outstationConstants).map((key) => (
        <TextField
          key={key}
          label={key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase())}
          value={outstationConstants[key]}
          onChange={(e) => handleOutstationChange(key, e.target.value)}
          className={classes.inputField}
          variant="outlined"
        />
      ))}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        className={classes.button}
      >
        Save Outstation Pricing
      </Button>

      {carData.outstationPricing && (
        <div className={classes.tableContainer}>
          <h4>Existing Outstation Pricing:</h4>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(outstationConstants).map((key) => (
                    <TableCell key={key} className={classes.tableHeaderCell}>
                      {key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.keys(outstationConstants).map((key) => (
                    <TableCell key={key} className={classes.tableCell}>
                      {outstationConstants[key]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default OutstationPricing;
