// RentalPricingUploader.js
import React, { useState } from "react";
import Papa from "papaparse";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles
} from "@material-ui/core";
import { api } from "common";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    marginTop: 20
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#000",
    color: "white"
  },
  tableCell: {
    padding: "10px"
  },
  uploadSection: {
    marginTop: 20
  },
  uploadButton: {
    marginLeft: 10
  },
  downloadButton: {
    marginTop: 10
  }
});

const RentalPricingUploader = ({ cabTypeData, carData }) => {
  console.log("hello ", cabTypeData);
  console.log("carData", carData)
  const classes = useStyles();
  const [csvFile, setCsvFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const dispatch = useDispatch();
  const { editCarType } = api;

  const handleDownloadCSV = () => {
    if (carData.rentalPricing && carData.rentalPricing.length > 0) {
      const csv = Papa.unparse(carData.rentalPricing);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${cabTypeData}_RentalPricing.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No rental pricing data available to download.");
    }
  };


  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
    setUploadError("");
  };

  const handleFileUpload = () => {
    if (!csvFile) {
      setUploadError("Please select a CSV file to upload.");
      return;
    }

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Validate and process the parsed data
        const parsedData = results.data;
        if (validateCsvData(parsedData)) {
          // Save data using Redux action
          savePricingData(carData, parsedData);
        } else {
          setUploadError("Invalid CSV format or data.");
        }
      },
      error: function (error) {
        console.error("Error parsing CSV:", error);
        setUploadError("Error parsing CSV file.");
      }
    });
  };

  const validateCsvData = (data) => {
    // Ensure data is an array and has required fields
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    const requiredFields = [
      "Cab",
      "Time",
      "Distance",
      "Charges",
      "Surge Charges"
    ];
    for (let row of data) {
      for (let field of requiredFields) {
        if (!(field in row) || row[field] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const savePricingData = (cabTypeData, pricingData) => {
    // Convert numeric fields from strings to numbers
    const formattedData = pricingData.map((row) => ({
      // Cab: row["Cab"],
      Time: parseFloat(row["Time"]),
      Distance: parseFloat(row["Distance"]),
      Charges: parseFloat(row["Charges"]),
      SurgeCharges: parseFloat(row["Surge Charges"])
    }));

    // Update the car type data with the new rental pricing
    const updatedCarType = {
      ...cabTypeData,
      rentalPricing: formattedData
    };
    console.log(updatedCarType)

    // Dispatch the editCarType action to update Firebase
    dispatch(editCarType(updatedCarType, "Update"))
      .then(() => {
        alert("Pricing data uploaded successfully!");
        setCsvFile(null);
      })
      .catch((error) => {
        console.error("Error updating car type:", error);
        setUploadError("Error saving data to the database.");
      });
  };

  return (
    <div>
      <h3>Upload Rental Pricing CSV for Cab Type {cabTypeData.name}</h3>
      <div className={classes.uploadSection}>
        <Input type="file" accept=".csv" onChange={handleFileChange} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFileUpload}
          className={classes.uploadButton}
        >
          Upload
        </Button>
      </div>

      {uploadError && <p style={{ color: "red" }}>{uploadError}</p>}
      {carData.rentalPricing && carData.rentalPricing.length > 0 && (
        <div className={classes.tableContainer}>
          <h4>Existing Rental Pricing Data:</h4>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="rental pricing table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    Time
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Distance
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Charges
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Surge Charges
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carData.rentalPricing.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      {row.Time}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.Distance}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.Charges}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.SurgeCharges}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadCSV}
            className={classes.downloadButton}
          >
            Download CSV
          </Button>
        </div>
      )}
    </div>
  );
};

export default RentalPricingUploader;
