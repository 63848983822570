const cityRidesPricingHelper = (
  carType,
  isSurgingEnabled,
  distance,
  decimal,
  rateDetails,
  totalTimeTaken = null,
) => {
  function calculateFare(equation, distance, time = null) {
    const a = equation.a;
    const b = equation.b;
    const c = equation.c;
    return a * Math.pow(distance, 2) + b * distance + c;
  }

  // Determine which pricing list to use
  const pricingList = isSurgingEnabled
    ? carType.cityRidesPricing.surgePricing
    : carType.cityRidesPricing.normalPricing;

  // Find the correct pricing object based on the distance
  let selectedPricing = null;
  for (let i = 0; i < pricingList.length; i++) {
    const pricing = pricingList[i];
    if (distance >= pricing.startInterval && distance <= pricing.endInterval) {
      selectedPricing = pricing;
      break;
    }
  }

  if (!selectedPricing) {
    throw new Error("Distance does not fall within any pricing interval.");
  }

  // Calculate the fare using the selected quadratic equation
  const fare = calculateFare(selectedPricing, distance, totalTimeTaken);

  return {
    totalCost: parseFloat(fare.toFixed(decimal)),
    grandTotal: parseFloat(fare.toFixed(decimal)),
    convenience_fees: 0,
  };
};

function calculateRentalCharges(data, distance = null, time = null, isSurgingEnabled = false) {
    // Determine which key to use based on surge pricing
    const chargeKey = isSurgingEnabled ? "SurgeCharges" : "Charges";

    // Helper function to interpolate values between two slots
    function interpolate(value, slot1, slot2, key) {
        const diff = slot2[key] - slot1[key];
        const ratio = (value - slot1[key]) / (slot2[key] - slot1[key]);
        return slot1[chargeKey] + ratio * diff;
    }

    // Sort data for safety (distance and time in ascending order)
    data.sort((a, b) => a.Distance - b.Distance || a.Time - b.Time);

    let charges = 0;
    if (distance !== null && time !== null) {
        // Find the closest slots for distance
        const distanceSlot = data.findIndex(slot => distance <= slot.Distance);
        const distanceBasedSlot = distanceSlot === -1 ? data[data.length - 1] : data[distanceSlot];
        
        // Find the closest slots for time
        const timeSlot = data.findIndex(slot => time <= slot.Time);
        const timeBasedSlot = timeSlot === -1 ? data[data.length - 1] : data[timeSlot];
        
        // Compare if distance or time exceeds its closest slot, and bill accordingly
        if (distance > distanceBasedSlot.Distance) {
            charges = timeBasedSlot[chargeKey]; // Bill based on time if distance exceeds
        } else if (time > timeBasedSlot.Time) {
            charges = distanceBasedSlot[chargeKey]; // Bill based on distance if time exceeds
        } else {
            // Interpolation if both are between two slots
            charges = Math.max(
                interpolate(distance, data[distanceSlot - 1], distanceBasedSlot, "Distance"),
                interpolate(time, data[timeSlot - 1], timeBasedSlot, "Time")
            );
        }
    } else if (distance !== null) {
        // Case when only distance is provided
        const distanceSlot = data.findIndex(slot => distance <= slot.Distance);
        if (distanceSlot === -1) {
            charges = data[data.length - 1][chargeKey];
        } else if (distanceSlot === 0) {
            charges = data[0][chargeKey];
        } else {
            charges = interpolate(distance, data[distanceSlot - 1], data[distanceSlot], "Distance");
        }
    } else if (time !== null) {
        // Case when only time is provided
        const timeSlot = data.findIndex(slot => time <= slot.Time);
        if (timeSlot === -1) {
            charges = data[data.length - 1][chargeKey];
        } else if (timeSlot === 0) {
            charges = data[0][chargeKey];
        } else {
            charges = interpolate(time, data[timeSlot - 1], data[timeSlot], "Time");
        }
    }

    return charges;
}

// Example usage:
const rentalData = [
    { Charges: 231, Distance: 10, SurgeCharges: 254.1, Time: 1 },
    { Charges: 424, Distance: 20, SurgeCharges: 466.4, Time: 2 },
    { Charges: 529, Distance: 30, SurgeCharges: 581.9, Time: 3 },
    { Charges: 799, Distance: 40, SurgeCharges: 878.9, Time: 4 },
    { Charges: 1049, Distance: 50, SurgeCharges: 1153.9, Time: 5 },
    { Charges: 1299, Distance: 60, SurgeCharges: 1428.9, Time: 6 },
    { Charges: 1499, Distance: 70, SurgeCharges: 1648.9, Time: 7 },
    { Charges: 1799, Distance: 80, SurgeCharges: 1978.9, Time: 8 },
    { Charges: 2899, Distance: 90, SurgeCharges: 3188.9, Time: 9 },
    { Charges: 3249, Distance: 100, SurgeCharges: 3573.9, Time: 10 },
    { Charges: 3599, Distance: 110, SurgeCharges: 3958.9, Time: 11 },
    { Charges: 3949, Distance: 120, SurgeCharges: 4343.9, Time: 12 },
    { Charges: 4649, Distance: 130, SurgeCharges: 5113.9, Time: 13 },
    { Charges: 5349, Distance: 140, SurgeCharges: 5883.9, Time: 14 },
    { Charges: 5600, Distance: 150, SurgeCharges: 6160, Time: 15 },
    { Charges: 6049, Distance: 160, SurgeCharges: 6653.9, Time: 16 },
    { Charges: 6200, Distance: 170, SurgeCharges: 6820, Time: 17 },
    { Charges: 6500, Distance: 180, SurgeCharges: 7150, Time: 18 }
];

// console.log(calculateRentalCharges(rentalData, 150, 18, true)); // With surge pricing
// console.log(calculateRentalCharges(rentalData, 100, null, true)); // With surge pricing, distance only
// console.log(calculateRentalCharges(rentalData, null, 5, false)); // Without surge pricing, time only

const rentalPricingHelper = (
  carType,
  isSurgingEnabled,
  distance,
  decimal,
  rateDetails,
  pickupTime,
  totalTimeTaken = null, // New parameter
) => {
  const chargeKey = isSurgingEnabled ? "SurgeCharges" : "Charges";
  const pricingData = carType.rentalPricing;

  // Sort data to ensure the order is maintained
  pricingData.sort((a, b) => a.Distance - b.Distance || a.Time - b.Time);

  let charges = 0;

  if (distance !== null && totalTimeTaken !== null) {
    const closestDistanceSlot = pricingData.findIndex(
      (slot) => distance <= slot.Distance,
    );
    const closestTimeSlot = pricingData.findIndex(
      (slot) => totalTimeTaken <= slot.Time,
    );

    if (closestDistanceSlot !== -1 && closestTimeSlot !== -1) {
      const distanceCharge = pricingData[closestDistanceSlot][chargeKey];
      const timeCharge = pricingData[closestTimeSlot][chargeKey];

      // Compare charges for the closest slots
      charges = Math.max(distanceCharge, timeCharge);
    } else {
      charges = pricingData[pricingData.length - 1][chargeKey];
    }
  } else if (distance !== null) {
    // Handle only distance
    const closestDistanceSlot = pricingData.findIndex(
      (slot) => distance <= slot.Distance,
    );
    charges =
      closestDistanceSlot !== -1
        ? pricingData[closestDistanceSlot][chargeKey]
        : pricingData[pricingData.length - 1][chargeKey];
  } else if (totalTimeTaken !== null) {
    // Handle only time
    const closestTimeSlot = pricingData.findIndex(
      (slot) => totalTimeTaken <= slot.Time,
    );
    charges =
      closestTimeSlot !== -1
        ? pricingData[closestTimeSlot][chargeKey]
        : pricingData[pricingData.length - 1][chargeKey];
  }

  return parseFloat(charges.toFixed(decimal));
};

const outstationPricingHelper = (
  carType,
  isSurgingEnabled,
  distance,
  decimal,
  rateDetails,
  isRoundTrip = false,
  pickupTime,
  dropTime,
  totalTimeTaken = null, // New parameter
) => {
  const totalCharges = calculateOutstationCharges(
    rateDetails.outstationPricing,
    distance,
    isRoundTrip,
    pickupTime,
    dropTime,
    totalTimeTaken, // Pass to helper
  );
  return {
    totalCost: totalCharges,
    grandTotal: totalCharges,
    convenience_fees: 0,
  };
};

function calculateOutstationCharges(
  outstationData,
  distance,
  isRoundTrip,
  pickupTime,
  dropTime,
  totalTimeTaken = null, // New parameter
) {
  const A = parseFloat(outstationData.A);
  const B = parseFloat(outstationData.B);
  const C = parseFloat(outstationData.C);
  const additionalCost1 = parseFloat(outstationData.additional_cost1);
  const additionalCost2 = parseFloat(outstationData.additional_cost2);
  const driverAllowancePerDay = parseFloat(outstationData.driver_allowance);
  const nightChargesPerDay = parseFloat(outstationData.night_charges);
  const roundTripDiscountPercentage = parseFloat(
    outstationData.round_trip_discount_percentage,
  );

  const baseFare = A * Math.pow(distance, 2) + B * distance + C;

  const totalAdditionalCosts = additionalCost1 + additionalCost2;

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const pickupDate = new Date(pickupTime);
  const dropDate = new Date(dropTime);
  let numberOfDays = totalTimeTaken
    ? Math.ceil(totalTimeTaken / (60 * 60 * 24)) // Use `totalTimeTaken` if provided
    : Math.ceil((dropDate - pickupDate) / millisecondsPerDay);

  numberOfDays = Math.max(numberOfDays, 1);

  const totalDriverAllowance = driverAllowancePerDay * numberOfDays;
  const totalNightCharges = nightChargesPerDay * numberOfDays;

  let totalFare =
    baseFare + totalAdditionalCosts + totalDriverAllowance + totalNightCharges;

  if (isRoundTrip) {
    const discount = (roundTripDiscountPercentage / 100) * totalFare;
    totalFare -= discount;
  }

  return parseFloat(totalFare.toFixed(2));
}

export const FareCalculator = (
  carType,
  isSurgingEnabled,
  distance,
  decimal,
  rateDetails,
  rideType = "cityRides",
  pickupTime = null,
  dropTime = null,
  totalTimeTaken = null,
) => {
  let charges = 0;
  console.log("react", pickupTime, dropTime);
  if (rideType === "cityRides") {
    return cityRidesPricingHelper(
      carType,
      isSurgingEnabled,
      distance,
      decimal,
      rateDetails,
      totalTimeTaken,
    );
  } else if (rideType === "rental") {
    charges = rentalPricingHelper(
      carType,
      isSurgingEnabled,
      distance,
      decimal,
      rateDetails,
      pickupTime,
      totalTimeTaken,
    );
  } else if (rideType === "outstation") {
    return outstationPricingHelper(
      carType,
      isSurgingEnabled,
      distance,
      decimal,
      rateDetails,
      false,
      pickupTime,
      dropTime,
      totalTimeTaken,
    );

    /*
      carType,
      isSurgingEnabled,
      distance,
      decimal,
      rateDetails,
      isRoundTrip = false,
      pickupTime,
      dropTime,
    */
  }

  return { totalCost: charges, grandTotal: charges, convenience_fees: 0 };
};

